// import { useRoutes } from "react-router-dom";
// import Themeroutes from "./routes/Router";

// const App = () => {
//   const routing = useRoutes(Themeroutes);

//   return <div className="dark">{routing}</div>;
// };

// export default App;





// import { useRoutes } from 'react-router-dom';
// import ThemeRoutes from './routes/Router';
// import { useAuth } from './context/AuthContext';

// const App = () => {
//   const { isLoggedIn } = useAuth();
//   const routes = ThemeRoutes(isLoggedIn);
//   const routing = useRoutes(routes);

//   return <div className="dark">{routing}</div>;
// };

// export default App;

// import { useRoutes } from 'react-router-dom';
// import ThemeRoutes from './routes/Router';
// import { useSelector } from 'react-redux'; // Import useSelector from react-redux

// const App = () => {
//   const isLoggedIn = useSelector((state) => state.isLoggedIn); // Get isLoggedIn from Redux state
//   const routes = ThemeRoutes(isLoggedIn);
//   const routing = useRoutes(routes);

//   return <div className="dark">{routing}</div>;
// };

// export default App;


import React from 'react';
import { useRoutes } from 'react-router-dom';
import ThemeRoutes from './routes/Router';

const AppContent = () => {
  const routing = useRoutes(ThemeRoutes());
  return routing;
};

const App = () => {
  return (
    <div className="dark">
      <AppContent />
    </div>
  );
};

export default App;
