// import { lazy } from "react";
// import { Navigate } from "react-router-dom";

// /****Layouts*****/
// const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
// const MinimalLayout = lazy(() => import("../layouts/MinimalLayout.js"));

// /***** Pages ****/

// const Starter = lazy(() => import("../views/Starter.js"));
// // const About = lazy(() => import("../views/About.js"));
// const Login = lazy(() => import("../views/Login.js"));
// const Alerts = lazy(() => import("../views/ui/Alerts"));
// const Badges = lazy(() => import("../views/ui/Badges"));
// const Buttons = lazy(() => import("../views/ui/Buttons"));
// const Cards = lazy(() => import("../views/ui/Cards"));
// const Grid = lazy(() => import("../views/ui/Grid"));
// const Tables = lazy(() => import("../views/ui/Tables"));
// const AndroidTable = lazy(() => import("../views/ui/AndroidTable"));
// const IosTable = lazy(() => import("../views/ui/iosTable"));
// const WebTable = lazy(() => import("../views/ui/WebTable"));
// const iotTable = lazy(() => import("../views/ui/ioTtable"));
// const APItable = lazy(() => import("../views/ui/APItable"));
// const Forms = lazy(() => import("../views/ui/Forms"));
// const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));

// /*****Routes******/


// const ThemeRoutes = [
//   {
//     path: "/",
//     element: <FullLayout />,
//     children: [
//       { path: "/", element: <Navigate to="/starter" /> },
//       { path: "/starter", exact: true, element: <Starter /> },
//       // { path: "/about", exact: true, element: <About /> },
//       { path: "/alerts", exact: true, element: <Alerts /> },
//       { path: "/badges", exact: true, element: <Badges /> },
//       { path: "/buttons", exact: true, element: <Buttons /> },
//       { path: "/cards", exact: true, element: <Cards /> },
//       { path: "/grid", exact: true, element: <Grid /> },
//       { path: "/table", exact: true, element: <Tables /> },
//       { path: "/AndroidFindings", exact: true, element: <AndroidTable /> },
//       { path: "/WebFindings", exact: true, element: <WebTable /> },
//       { path: "/IotFindings", exact: true, element: <iotTable /> },
//       { path: "/APIfindings", exact: true, element: <APItable /> },
//       { path: "/IosFindings", exact: true, element: <IosTable /> },
//       { path: "/forms", exact: true, element: <Forms /> },
//       { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
//     ],
//   },
//   {
//     path: "/login",
//     element: <MinimalLayout />,
//     children: [
//       { path: "/login", exact: true, element: <Login /> },
//     ],
//   },
// ];

// export default ThemeRoutes;


// import React, { lazy } from 'react';
// import { Navigate } from 'react-router-dom';
// import ProtectedRoute from './ProtectedRoute';

// /****Layouts*****/
// const FullLayout = lazy(() => import('../layouts/FullLayout.js'));
// const MinimalLayout = lazy(() => import('../layouts/MinimalLayout.js'));

// /***** Pages ****/
// const Starter = lazy(() => import('../views/Starter.js'));
// const Login = lazy(() => import('../views/Login.js'));
// const Alerts = lazy(() => import('../views/ui/Alerts'));
// const Badges = lazy(() => import('../views/ui/Badges'));
// const Buttons = lazy(() => import('../views/ui/Buttons'));
// const Cards = lazy(() => import('../views/ui/Cards'));
// const Grid = lazy(() => import('../views/ui/Grid'));
// const Tables = lazy(() => import('../views/ui/Tables'));
// const AndroidTable = lazy(() => import('../views/ui/AndroidTable'));
// const IosTable = lazy(() => import('../views/ui/iosTable'));
// const WebTable = lazy(() => import('../views/ui/WebTable'));
// const IotTable = lazy(() => import('../views/ui/ioTtable'));
// const APItable = lazy(() => import('../views/ui/APItable'));
// const Forms = lazy(() => import('../views/ui/Forms'));
// const Breadcrumbs = lazy(() => import('../views/ui/Breadcrumbs'));

// /*****Routes******/
// const ThemeRoutes = (isLoggedIn) => [
//   {
//     path: '/',
//     element: isLoggedIn ? <FullLayout /> : <Navigate to="/login" />,
//     children: [
//       { path: '/', element: <Navigate to="/starter" /> },
//       { path: '/starter', exact: true, element: <ProtectedRoute><Starter /></ProtectedRoute> },
//       { path: '/alerts', exact: true, element: <ProtectedRoute><Alerts /></ProtectedRoute> },
//       { path: '/badges', exact: true, element: <ProtectedRoute><Badges /></ProtectedRoute> },
//       { path: '/buttons', exact: true, element: <ProtectedRoute><Buttons /></ProtectedRoute> },
//       { path: '/cards', exact: true, element: <ProtectedRoute><Cards /></ProtectedRoute> },
//       { path: '/grid', exact: true, element: <ProtectedRoute><Grid /></ProtectedRoute> },
//       { path: '/table', exact: true, element: <ProtectedRoute><Tables /></ProtectedRoute> },
//       { path: '/AndroidFindings', exact: true, element: <ProtectedRoute><AndroidTable /></ProtectedRoute> },
//       { path: '/WebFindings', exact: true, element: <ProtectedRoute><WebTable /></ProtectedRoute> },
//       { path: '/IotFindings', exact: true, element: <ProtectedRoute><IotTable /></ProtectedRoute> },
//       { path: '/APIfindings', exact: true, element: <ProtectedRoute><APItable /></ProtectedRoute> },
//       { path: '/IosFindings', exact: true, element: <ProtectedRoute><IosTable /></ProtectedRoute> },
//       { path: '/forms', exact: true, element: <ProtectedRoute><Forms /></ProtectedRoute> },
//       { path: '/breadcrumbs', exact: true, element: <ProtectedRoute><Breadcrumbs /></ProtectedRoute> },
//     ],
//   },
//   {
//     path: '/login',
//     element: <MinimalLayout />,
//     children: [{ path: '/login', exact: true, element: <Login /> }],
//   },
// ];

// export default ThemeRoutes;


// import React, { lazy, useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import ProtectedRoute from './ProtectedRoute';

// const FullLayout = lazy(() => import('../layouts/FullLayout.js'));
// const MinimalLayout = lazy(() => import('../layouts/MinimalLayout.js'));


// const Starter = lazy(() => import('../views/Starter.js'));
// const Login = lazy(() => import('../views/Login.js'));
// //const MyAccount = lazy(() => import('../views/MyAccount.js'));
// const NotFound = lazy(() => import('../views/NotFound.js')); 
// const Alerts = lazy(() => import('../views/ui/Alerts'));
// const Badges = lazy(() => import('../views/ui/Badges'));
// const Buttons = lazy(() => import('../views/ui/Buttons'));
// const Cards = lazy(() => import('../views/ui/Cards'));
// const Grid = lazy(() => import('../views/ui/Grid'));
// const Tables = lazy(() => import('../views/ui/Tables'));
// const AndroidTable = lazy(() => import('../views/ui/AndroidTable'));
// const IosTable = lazy(() => import('../views/ui/iosTable'));
// const WebTable = lazy(() => import('../views/ui/WebTable'));
// const IotTable = lazy(() => import('../views/ui/ioTtable'));
// const APItable = lazy(() => import('../views/ui/APItable'));
// const Forms = lazy(() => import('../views/ui/Forms'));
// const Breadcrumbs = lazy(() => import('../views/ui/Breadcrumbs'));


// const ThemeRoutes = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     setIsLoggedIn(!!token);
//   }, []);

//   return [
//     {
//       path: '/',
//       element: isLoggedIn ? <FullLayout /> : <Navigate to="/login" />,
//       children: [
//         { path: '/', element: <Navigate to="/starter" /> },
//         { path: '/starter', exact: true, element: <ProtectedRoute><Starter /></ProtectedRoute> },
//         { path: '/alerts', exact: true, element: <ProtectedRoute><Alerts /></ProtectedRoute> },
//         { path: '/badges', exact: true, element: <ProtectedRoute><Badges /></ProtectedRoute> },
//         { path: '/buttons', exact: true, element: <ProtectedRoute><Buttons /></ProtectedRoute> },
//         { path: '/cards', exact: true, element: <ProtectedRoute><Cards /></ProtectedRoute> },
//         { path: '/grid', exact: true, element: <ProtectedRoute><Grid /></ProtectedRoute> },
//         { path: '/table', exact: true, element: <ProtectedRoute><Tables /></ProtectedRoute> },
//         { path: '/AndroidFindings', exact: true, element: <ProtectedRoute><AndroidTable /></ProtectedRoute> },
//         { path: '/WebFindings', exact: true, element: <ProtectedRoute><WebTable /></ProtectedRoute> },
//         { path: '/IotFindings', exact: true, element: <ProtectedRoute><IotTable /></ProtectedRoute> },
//         { path: '/APIfindings', exact: true, element: <ProtectedRoute><APItable /></ProtectedRoute> },
//         { path: '/IosFindings', exact: true, element: <ProtectedRoute><IosTable /></ProtectedRoute> },
//         { path: '/forms', exact: true, element: <ProtectedRoute><Forms /></ProtectedRoute> },
//         { path: '/breadcrumbs', exact: true, element: <ProtectedRoute><Breadcrumbs /></ProtectedRoute> },
//       //  { path: '/my-account', exact: true, element: <ProtectedRoute><MyAccount /></ProtectedRoute> }, // Add MyAccount route
//         { path: '*', element: <ProtectedRoute><NotFound /></ProtectedRoute> } // Catch-all route for 404
//       ],
//     },
//     {
//       path: '/login',
//       element: <MinimalLayout />,
//       children: [{ path: '/login', exact: true, element: <Login /> }],
//     },
//     { path: '*', element: <NotFound /> }, 
//   ];
// };

// export default ThemeRoutes;



import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';

/****Layouts*****/
const FullLayout = lazy(() => import('../layouts/FullLayout.js'));
const MinimalLayout = lazy(() => import('../layouts/MinimalLayout.js'));

/***** Pages ****/
const Starter = lazy(() => import('../views/Starter.js'));
const Login = lazy(() => import('../views/Login.js'));
const NotFound = lazy(() => import('../views/NotFound.js')); // Import NotFound
const Alerts = lazy(() => import('../views/ui/Alerts'));
const Badges = lazy(() => import('../views/ui/Badges'));
const Buttons = lazy(() => import('../views/ui/Buttons'));
const Cards = lazy(() => import('../views/ui/Cards'));
const Grid = lazy(() => import('../views/ui/Grid'));
const Tables = lazy(() => import('../views/ui/Tables'));
const AndroidTable = lazy(() => import('../views/ui/AndroidTable'));
const IosTable = lazy(() => import('../views/ui/iosTable'));
const WebTable = lazy(() => import('../views/ui/WebTable'));
const IotTable = lazy(() => import('../views/ui/ioTtable'));
const APItable = lazy(() => import('../views/ui/APItable'));
const Forms = lazy(() => import('../views/ui/Forms'));
const Breadcrumbs = lazy(() => import('../views/ui/Breadcrumbs'));

/*****Routes******/
const ThemeRoutes = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn); // Get isLoggedIn from Redux state

  return [
    {
      path: '/',
      element: isLoggedIn ? <FullLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to="/starter" /> },
        { path: '/starter', exact: true, element: <ProtectedRoute><Starter /></ProtectedRoute> },
        { path: '/alerts', exact: true, element: <ProtectedRoute><Alerts /></ProtectedRoute> },
        { path: '/badges', exact: true, element: <ProtectedRoute><Badges /></ProtectedRoute> },
        { path: '/buttons', exact: true, element: <ProtectedRoute><Buttons /></ProtectedRoute> },
        { path: '/cards', exact: true, element: <ProtectedRoute><Cards /></ProtectedRoute> },
        { path: '/grid', exact: true, element: <ProtectedRoute><Grid /></ProtectedRoute> },
        { path: '/table', exact: true, element: <ProtectedRoute><Tables /></ProtectedRoute> },
        { path: '/AndroidFindings', exact: true, element: <ProtectedRoute><AndroidTable /></ProtectedRoute> },
        { path: '/WebFindings', exact: true, element: <ProtectedRoute><WebTable /></ProtectedRoute> },
        { path: '/IotFindings', exact: true, element: <ProtectedRoute><IotTable /></ProtectedRoute> },
        { path: '/APIfindings', exact: true, element: <ProtectedRoute><APItable /></ProtectedRoute> },
        { path: '/IosFindings', exact: true, element: <ProtectedRoute><IosTable /></ProtectedRoute> },
        { path: '/forms', exact: true, element: <ProtectedRoute><Forms /></ProtectedRoute> },
        { path: '/breadcrumbs', exact: true, element: <ProtectedRoute><Breadcrumbs /></ProtectedRoute> },
        { path: '*', element: <ProtectedRoute><NotFound /></ProtectedRoute> } // Catch-all route for 404
      ],
    },
    {
      path: '/login',
      element: <MinimalLayout />,
      children: [{ path: '/login', exact: true, element: <Login /> }],
    },
    { path: '*', element: <NotFound /> }, // Catch-all route for 404 outside the authenticated routes
  ];
};

export default ThemeRoutes;

