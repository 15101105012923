import { configureStore, createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: localStorage.getItem("isLoggedIn") === "true", // Initialize from localStorage
  },
  reducers: {
    login(state) {
      state.isLoggedIn = true;
      localStorage.setItem("isLoggedIn", "true"); // Save to localStorage
    },
    logout(state) {
      state.isLoggedIn = false;
      localStorage.removeItem("isLoggedIn"); // Remove from localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("companyid");
    },
  },
});

export const authActions = authSlice.actions;

export const store = configureStore({
  reducer: authSlice.reducer,
});

