// import React, { Suspense } from "react";
// // import ReactDOM from "react-dom";
// import {createRoot} from 'react-dom/client';
// import "./assets/scss/style.scss";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { HashRouter } from "react-router-dom";
// import Loader from "./layouts/loader/Loader";

// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// root.render(
//   <Suspense fallback={<Loader />}>
//     <HashRouter>
//       <App />
//     </HashRouter>
//   </Suspense>,


// );


// reportWebVitals();



// import React, { Suspense } from "react";
// import { createRoot } from 'react-dom/client';
// import "./assets/scss/style.scss";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { HashRouter } from "react-router-dom";
// import Loader from "./layouts/loader/Loader";
// import { AuthProvider } from './context/AuthContext';

// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// root.render(
//   <Suspense fallback={<Loader />}>
//     <AuthProvider>
//       <HashRouter>
//         <App />
//       </HashRouter>
//     </AuthProvider>
//   </Suspense>,
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();




// import React, { Suspense } from "react";
// import { createRoot } from "react-dom/client";
// import "./assets/scss/style.scss";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { HashRouter } from "react-router-dom";
// import Loader from "./layouts/loader/Loader";
// import { AuthProvider } from "./context/AuthContext";
// import { ToastContainer } from "react-toastify"; // Import ToastContainer
// import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

// root.render(
//   <Suspense fallback={<Loader />}>
//     <AuthProvider>
//       <HashRouter>
//         <App />
//         <ToastContainer /> {/* Add ToastContainer */}
//       </HashRouter>
//     </AuthProvider>
//   </Suspense>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import { Provider } from 'react-redux';
import { store } from './components/store'; // Import the Redux store
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}> {/* Add Redux Provider */}
      <HashRouter>
        <App />
        <ToastContainer /> {/* Add ToastContainer */}
      </HashRouter>
    </Provider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
